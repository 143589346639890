// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDsqcE150NpruXo6RbhHWMSVyRH9dlyNRg",
  authDomain: "yahyawebsite-de18d.firebaseapp.com",
  projectId: "yahyawebsite-de18d",
  storageBucket: "yahyawebsite-de18d.appspot.com",
  messagingSenderId: "914226258679",
  appId: "1:914226258679:web:28431bf06affbbc142a9f2",
  measurementId: "G-MJF1K22KGY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, signOut };
