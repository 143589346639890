import { DarkHeader } from "../headers/dark-header/dark-header";

import { MainMenu } from "../menu/menu";
import { Spinner } from "../spinner/spinner";
import { Checks } from "./components/checks/checks";
import { Faq } from "./components/faq/faq.html";
import { Steps } from "./components/steps/steps";

const renderSteps = (item, index) => {
  return <Steps item={item} key={index} />;
};
const renderChecks = (item, index) => {
  return <Checks item={item} key={index} />;
};
const renderSection = (item, index) => {
  switch (item.type) {
    case "steps":
      return renderSteps(item, index);
    case "checks":
      return renderChecks(item, index);
    default:
      return <div></div>;
  }
};
export const aboutHtml = (content) => {
  return (
    <div>
      <MainMenu location="about" />
      <DarkHeader title="About me" />
      {content.map((item, index) => renderSection(item, index))}
      <Faq />
    </div>
  );
};

export const loadingAboutHtml = () => {
  return (
    <div>
      <MainMenu location="about" />
      <DarkHeader title="About me" />
      <Spinner />
      <Faq />
    </div>
  );
};
