import "./App.css";
import React, { Suspense } from "react";
import { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/home/home";
import { About } from "./components/about/about";
import { Blog } from "./components/blog/blog";
import { Docs } from "./components/docs/docs";
import { QueryDisplayContent } from "./components/querycontent/querycontent";
import { Tools } from "./components/tools/tools";
import { Courses } from "./components/courses/courses";
import { CourseDisplay } from "./components/courses/course-display";
import { AdminMain } from "./components/admin/main/admin-main";
import { AdminLogin } from "./components/admin/login/admin-login";
import { ProtectedRoute } from "./components/admin/login/protected-route";
import FriendsgivingPage from "./components/fun/friendsgiving/friends-giving";

class App extends Component {
  render() {
    return (
      <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/keys" element={<QueryDisplayContent />} />
        <Route path="/blog/:id" element={<Blog id={this.props}/>} />
        <Route path="/tools" element={<Tools />}/>
        <Route path="/friends-giving" element={< FriendsgivingPage/>}/>
        <Route path="/tools/:name" element={<Tools />}/>
        <Route path="/courses" element={<Courses />}/>
        <Route path="/courses/:name" element={<CourseDisplay />}/>
        <Route path="/login" element={<AdminLogin />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminMain />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <AdminMain />
            </ProtectedRoute>
          }
        />
        </Routes>
      </Suspense>
      </Router>
    );
  }
}

export default App;
