import './small-dark-header.css';

export function SmallDarkHeader(props) {
  const title = props.title;
  const content = props.content;
  return (
      <div className="small-light-header-section">
        <h1>{title}</h1>
        <p>{content}</p>
      </div>
  );    
}
