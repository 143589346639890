import React, { useState, useEffect } from 'react';
import './regex-tester.css';


export function RegexTester(props) {
  const [regex, setRegex] = useState('');
  const [testString, setTestString] = useState('');
  const [isMatch, setIsMatch] = useState(null); // true, false, or null for no result
  const [error, setError] = useState('');

  // Update match result automatically when regex or testString changes
  useEffect(() => {
    if (!regex) {
      setIsMatch(null);
      setError('');
      return;
    }

    try {
      // Clear previous results and errors
      setIsMatch(null);
      setError('');

      // Create a new RegExp object and test whether the whole string matches
      const regexPattern = new RegExp(`^${regex}$`); // Add ^ and $ to match the entire string
      const result = regexPattern.test(testString);
      setIsMatch(result);
    } catch (err) {
      // Catch regex errors and display them
      setError(`Invalid regex: ${err.message}`);
      setIsMatch(null);
    }
  }, [regex, testString]);

  const handleClear = () => {
    setRegex('');
    setTestString('');
    setIsMatch(null);
    setError('');
  };

  return (
    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div className="results-container">
        <h3>Match Result:</h3>
        {isMatch === null ? (
          <p>No regex provided or no result yet.</p>
        ) : isMatch ? (
          <p className="match-success">Match</p>
        ) : (
          <p className="match-failure">No Match.</p>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="input-container">
        <textarea
          placeholder="Enter your regular expression..."
          value={regex}
          onChange={(e) => setRegex(e.target.value)}
          className="regex-textarea"
        />
        <textarea
          placeholder="Enter the test string..."
          value={testString}
          onChange={(e) => setTestString(e.target.value)}
          className="regex-textarea"
        />
      </div>

      <div className="button-container">
        <button onClick={handleClear} className="clear-button">Clear</button>
      </div>
    </div>
  );
};

export default RegexTester;
