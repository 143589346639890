import { Component } from "react";
import { DarkHeader } from "../headers/dark-header/dark-header";
import { MainMenu } from "../menu/menu";
import "./querycontent.css";

export class QueryDisplayContent extends Component {
  render() {
    return (
      <div>
        <MainMenu location="" />
        <DarkHeader title="Keys Hub" />
        <DocsContent />
      </div>
    );
  }
}

class DocsContent extends Component {

render() {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [name, value] of queryParams) {
        params[name] = value;
    }

    return (
        <div className="full-height">
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                {Object.entries(params).map(([name, value]) => (
                    <div className="query-content" key={name}>
                        {name}: {value}
                    </div>
                ))}
            </div>
        </div>
    );
}
}
