import { useParams } from "react-router-dom";
import { LightHeader } from "../headers/light-header/light-header";
import { SmallDarkHeader } from "../headers/small-dark-header/small-dark-header";
import { HeadlessArchitecture } from "./headless-architecture/headless-architecture";
import { MainMenu } from "../menu/menu";
import "./courses.css";

export function CourseDisplay(props) {
  const { name } = useParams();
  switch (name) {
    case "headless-architecture":
      return (
        <div>
          <MainMenu location="courses" />
          <SmallDarkHeader title="Headless Architecture" />
          <HeadlessArchitecture />
        </div>
      );
    default:
      return (
        <div>
          <MainMenu location="courses" />
          <LightHeader title="Course doesn't exist" />
        </div>
      );
  }
}
