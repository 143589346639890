import React, { useState } from 'react';
import { auth } from "../../../firebase-config";
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export function AdminMenu(){
  const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error logging out: ', error);
        }
    };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex items-center justify-between">
        {/* Brand Logo */}
        <div className="text-white text-2xl font-bold">Admin Panel</div>

        {/* Hamburger Button for Mobile */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        {/* Menu Links */}
        <div className={`lg:flex space-x-4 ${isOpen ? 'hidden' : 'hidden'} lg:block`}>
          <button className="text-white py-2 px-4 rounded-lg hover:bg-indigo-600 focus:outline-none" onClick={() => navigate('/admin/blog')} >
            Blog Manager
          </button>
          <button className="text-white py-2 px-4 rounded-lg hover:bg-indigo-600 focus:outline-none" onClick={() => navigate('/admin/resume-manager')}>
            Resume Manager
          </button>
          <button className="text-white py-2 px-4 rounded-lg hover:bg-indigo-600 focus:outline-none" onClick={() => navigate('/admin/documents')}>
            Document Manager
          </button>
          <button 
            onClick={handleLogout} 
            className="bg-indigo-200 text-gray py-2 px-4 rounded-lg shadow hover:bg-indigo-600 focus:outline-none"
          >
            Logout
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="lg:hidden mt-4 space-y-4">
          <button className="text-white py-2 px-4 rounded-lg hover:bg-indigo-600 focus:outline-none" onClick={() => navigate('/admin/blog')} >
            Blog Manager
          </button>
          <button className="text-white py-2 px-4 rounded-lg hover:bg-indigo-600 focus:outline-none" onClick={() => navigate('/admin/documents')} >
            Document Manager
          </button>
          <button 
            onClick={handleLogout} 
            className="bg-indigo-200 text-gray py-2 px-4 rounded-lg shadow hover:bg-indigo-600 focus:outline-none"
          >
            Logout
          </button>
        </div>
      )}
    </nav>
  );
};

