import { useState } from "react";
import "./headless-architecture.css";

const courseContent = {
  title: "Headless Architecture",
  lessons: [
    { id: 1, title: "What is Headless Architecture?", content: "Headless architecture is a design principle that decouples the front-end and back-end of a web application." },
    { id: 2, title: "Why Use Headless Architecture?", content: "Headless architecture allows for more flexibility in the design and development of web applications." },
    { id: 3, title: "How to Implement Headless Architecture", content: "To implement headless architecture, you need to create separate front-end and back-end components that communicate with each other using APIs." }
  ]
};

export function HeadlessArchitecture() {
  const [selectedLesson, setSelectedLesson] = useState(courseContent.lessons[0]);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Dropdown Button for Small Screens */}
      <div className="md:hidden p-4 blue-bg text-white">
        <button
          className="px-4 py-2 text-gray-800 font-medium bg-blue-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? "Close Menu" : "Select Chapter"}
        </button>
      </div>

      {/* Side Navigation Menu */}
      <aside
        className={`w-full md:w-1/5 blue-bg text-white p-5 md:block ${
          menuOpen ? "block" : "hidden"
        }`}
      >
        <ul className="space-y-2">
          {courseContent.lessons.map((lesson) => (
            <li key={lesson.id}>
              <button
                className={`block w-full text-left px-4 py-2 rounded-lg hover:bg-gray-600 ${
                  selectedLesson.id === lesson.id ? "bg-gray-300 text-gray-900" : ""
                }`}
                onClick={() => {
                  setSelectedLesson(lesson);
                  setMenuOpen(false); // Close menu when a lesson is selected on mobile
                }}
              >
                {lesson.title}
              </button>
            </li>
          ))}
        </ul>
      </aside>

      {/* Main Course Content */}
      <main className="w-full md:w-4/5 p-8 bg-white">
        <h1 className="text-2xl font-bold mb-4">{selectedLesson.title}</h1>
        <p className="text-base text-gray-700">{selectedLesson.content}</p>
      </main>
    </div>
  );
}
