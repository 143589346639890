import './light-header.css';

export function LightHeader(props) {
  const title = props.title;
  const content = props.content;
  return (
      <div className="detatch-light-header-section">
        <h1>{title}</h1>
        <p>{content}</p>
      </div>
  );    
}
