import React from "react";
import { MainMenu } from "../../menu/menu";

export default function FriendsgivingPage() {

  return (
    <div>
        <MainMenu location="friendsgiving" navColor="900" />
        <div className="min-h-screen bg-gradient-to-r from-blue-100 to-yellow-100 pb-8">
          <header className="min-h-screen bg-gradient-to-r from-orange-900 to-yellow-700 text-white text-center py-6">
             <h1 className="text-5xl font-bold">🍂 Friendsgiving 2024 🍂</h1>
             <p className="text-2xl mt-2"> Friendsgiving 2024 is over, We will prepare for 2025</p>
           </header>
        </div>
    </div>
  );
}
