import { MainMenu } from "../../menu/menu";
import { AdminMenu } from "../menu/admin-menu";
import { useLocation } from "react-router-dom";
import { AdminBlogManager } from "../blog-manager/admin-blog-manager";
import { DocumentDrive } from "../document-manager/admin-document-manager";

export function AdminMain(props) {
    const location = useLocation();
    const path = location.pathname.replace("/", "").split("/");
    const currentPage = path.length > 1 ? path[1] : "admin";
    return (
        <div>
            <MainMenu location="admin-main" />
            <AdminMenu />
            {currentPage === "blog" && <AdminBlogManager />}
            {currentPage === "documents" && <DocumentDrive />}
        </div>
    )
}