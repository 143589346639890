import { Component } from "react";

export class ToolsDisplay extends Component {
  tools = [
    {
        name: 'JSON Formatter',
        icon: '/img/tools/json-formatter.png',
        description: 'A simple JSON formatter that allows you to format JSON data in a readable way.',
        link: '/tools/json-formatter'
    },
    {
        name: 'YAML Formatter',
        icon: '/img/tools/yaml-formatter.png',
        description: 'A simple YAML formatter that allows you to format YAML data in a readable way.',
        link: '/tools/yaml-formatter'
    },
    {
        name: 'Color Picker',
        icon: '/img/tools/color-picker.png',
        description: 'A simple color picker that allows you to pick a color from a color palette.',
        link: '/tools/color-picker'
    },
    {
        name: 'UUID Generator',
        icon: '/img/tools/uuid.png',
        description: 'Generates a random UUID.',
        link: '/tools/uuid-generator'
    },
    {
        name: 'JSON Schema Validator',
        icon: '/img/tools/json-schema-validator.png',
        description: 'A simple JSON schema validator that allows you to validate JSON data against a schema.',
        link: '/tools/json-schema-validator'
    },
    {
        name: 'Regex Tester',
        icon: '/img/tools/regex-tester.png',
        description: 'Allows you to test regular expressions against a string.',
        link: '/tools/regex-tester'
    }
  ]
  render() {
    return (
    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {this.tools.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
    </div>
    );
  }
}

class ToolCard extends Component {
  render() {
    return (
      <div className="p-4 bg-white rounded-lg shadow-md">
        <div className="flex flex-col items-center">
          <img src={this.props.tool.icon} className="h-16 w-16" alt={this.props.tool.name} />
          <h3 className="text-lg font-semibold mt-4">{this.props.tool.name}</h3>
          <p className="text-sm text-gray-600 mt-2">{this.props.tool.description}</p>

          <a href={this.props.tool.link} className="px-5 py-2 my-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
              Try it
          </a>
        </div>
      </div>
    );
  }
}