import { useState } from "react";
import { LightHeader } from "../headers/light-header/light-header";
import { MainMenu } from "../menu/menu";
import './courses.css';

const courses = [
  {
    name: "Headless Architecture",
    description: "Headless Architecture is the new way to build your applications, learn the basics here",
    link: "/courses/headless-architecture"
  },
  // {
  //   name: "Serverless Computing",
  //   description: "Learn how to build scalable and cost-effective applications using serverless computing",
  //   link: "/courses/serverless-computing"
  // },
  // {
  //   name: "Microservices Architecture",
  //   description: "Discover the benefits and best practices of building applications using microservices architecture",
  //   link: "/courses/microservices-architecture"
  // },
  // {
  //   name: "Cloud Native Development",
  //   description: "Learn how to develop and deploy applications in a cloud-native environment",
  //   link: "/courses/cloud-native-development"
  // },
  // {
  //   name: "DevOps Fundamentals",
  //   description: "Master the principles and tools of DevOps to streamline your software development process",
  //   link: "/courses/devops-fundamentals"
  // },
  // {
  //   name: "Containerization with Docker",
  //   description: "Get hands-on experience with Docker and learn how to containerize your applications",
  //   link: "/courses/containerization-with-docker"
  // }
];

export function Courses(props) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCourses = courses.filter(course => 
    course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <MainMenu location="courses" />
      <LightHeader title="Courses" content="Page Under Construction"/>
      <div className="courses-section">
        <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          
          {/* Search Bar */}
          <div className="mb-6">
            <input
              type="text"
              placeholder="Search for courses"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Course Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            {filteredCourses.length > 0 ? (
              filteredCourses.map((course, index) => (
                <CourseCard key={index} course={course} />
              ))
            ) : (
              <p>No courses found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );    
}

function CourseCard(props) {
  return (
    <div className="course-card flex flex-col h-full px-5 py-10 bg-white rounded-lg shadow-md">
      <h1>{props.course.name}</h1>
      <p className="flex-grow">{props.course.description}</p>
      
      <div className="mt-auto self-end">
        <a
          href={props.course.link}
          className="inline-flex items-center justify-center px-3 py-1 text-sm font-medium text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
        >
          <span className="mr-1">Access</span>
          <svg
            className="w-4 h-4 transform rotate-45"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </a>
      </div>
    </div>
  );
}
