import { Component } from "react";
import { loadingblogPreviewsHtml } from "../blog/components/blog-previews/blog-previews.html";
import { DarkHeader } from "../headers/dark-header/dark-header";
import axios from "axios";
import { MainMenu } from "../menu/menu";
import './docs.css';


let serviceUrl =
    "https://a34oglmw79.execute-api.us-east-1.amazonaws.com/default/docs";

export class Docs extends Component {
  render() {
    return (
      <div>
        <MainMenu location="" />
        <DarkHeader title="Documents hub" />
        <DocsContent />
      </div>
    );
  }
}

class DocsContent extends Component {
  
  constructor(props) {
    super(props);
    this.state = { content: "", loading: true, showPopup: false};
  }
  retrieveDocsContent() {
    return axios.get(serviceUrl).then((content) => {
      this.content = content.data;
      this.setState({ content: content.data, loading: false });
    });
  }
  render() {
    if (this.state.loading) {
      this.retrieveDocsContent();
      return loadingblogPreviewsHtml();
    } else {
      return (
        <div className="full-height">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            {this.state.content.map((doc, index) => (
              <div className="p-8 rounded shadow sm:p-16">
                <button onClick={()=>{this.setState({showPopup: true})}}>{doc}</button>
                <DocsPopup show={this.state.showPopup} document={doc} handleClick={()=>{this.setState({showPopup: false})}}/>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}

class DocsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = { status: 'idle', inputValue:'' };
  }
  
  getDoc(auth, document){
    axios.get(`${serviceUrl}?auth=${auth}&resource=${encodeURI(document)}`).then((response) => {
      let content = response.data;
      if(content.hasOwnProperty('link')){
        this.setState({status: 'success'})
        window.location.href = content.link;
      }
      else{
        this.setState({status: 'error'})
      }
    });
  }

  updateInputValue(evt) {
    const val = evt.target.value;       
    this.setState({
      inputValue: val
    });
  }
  render() {
    if(this.props.show){
    return (
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 sm:mx-0 sm:h-10 sm:w-10">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Retrieve Document</h3>
                  {this.state.status === 'error' ? <p className="text-base text-red-900">Wrong passcode</p>: ''}
                  <div className="mt-2">
                  <label for="passcode-address" className="sr-only">Enter passcode</label>
                  <input id="passcode-address" onChange={evt => this.updateInputValue(evt)} name="passcode" type="passcode" autocomplete="passcode" required className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your passcode"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button type="button" className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" onClick={()=>this.getDoc(this.state.inputValue, this.props.document)}>Get Link</button>
              <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={this.props.handleClick}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    );
    }
  }
}
