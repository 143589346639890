import React, { useState, useEffect } from 'react';
import axios from "axios";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';


function createPost(id, title, content, categorie) {
    const auth = getAuth();
    const post = {
        id,
        title,
        content,
        categorie,
    }
    auth.currentUser.getIdToken().then((token) => {
        axios.post("https://62wv1nusre.execute-api.us-east-1.amazonaws.com/default/createBlogPost", post, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then(response => {
            console.log('Post created:', response.data);
            })
            .catch(error => {
            console.error('Error creating post:', error);
            });
    });
}


function updatePost(id, title, content, categorie) {
    const auth = getAuth();
    const post = {
        id,
        title,
        content,
        categorie,
    }
    auth.currentUser.getIdToken().then((token) => {
        axios.post("https://62wv1nusre.execute-api.us-east-1.amazonaws.com/default/createBlogPost", post, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        }).then(response => {
            console.log('Post updated:', response.data);
        }).catch(error => {
            console.error('Error updating post:', error);
        });
    });
}

function deletePost(id) {
    const auth = getAuth();
    auth.currentUser.getIdToken().then((token) => {
        axios.delete(`https://62wv1nusre.execute-api.us-east-1.amazonaws.com/default/createBlogPost`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            data: {
                id
            }
        }).then(response => {
            console.log('Post deleted:', response.data);
        }).catch(error => {
            console.error('Error deleting post:', error);
        });
    });
}

export function AdminBlogManager(){
    const serviceUrl = "https://5zu8h1w5o9.execute-api.us-east-1.amazonaws.com/default/blog";

    const [posts, setPosts] = useState([]);

    useEffect(() => {
      axios.get(serviceUrl)
        .then(response => {
          setPosts(response.data);
        })
        .catch(error => {
          console.error('Error retrieving posts:', error);
        });
    }, []);
  
    const [editingPost, setEditingPost] = useState(null); // Track the post being edited
    const [isCreating, setIsCreating] = useState(false); // Track if we are creating a new post
    const [newPost, setNewPost] = useState({
      title: '',
      content: '',
      categorie: '',
      updatedAt: new Date().toLocaleDateString(),
    });
  
    // Handle creating a new post
    const handleCreate = () => {
      const newPostWithId = {
        ...newPost,
        id: uuidv4(),
      };
      createPost(newPostWithId.id, newPostWithId.title, newPostWithId.content, newPostWithId.categorie);
      setPosts([...posts, newPostWithId]);
      clearForm(); // Clear the form after creation
      setIsCreating(false); // Go back to the table view after creating
    };
  
    // Handle editing an existing post
    const handleEdit = (post) => {
      setEditingPost(post);
      setNewPost({
        title: post.title,
        content: post.content,
        categorie: post.categorie,
        updatedAt: post.updatedAt,
      });
    };
  
    const handleSaveEdit = () => {
      updatePost(editingPost.id, newPost.title, newPost.content, newPost.categorie);
      setPosts(
        posts.map((post) => {
            
            return post.id === editingPost.id ? { ...post, ...newPost } : post;
        }
        )
      );
      setEditingPost(null);
      clearForm();
    };
  
    // Handle deleting a post
    const handleDelete = (id) => {
      deletePost(id);
      setPosts(posts.filter((post) => post.id !== id));
    };
  
    const clearForm = () => {
      setNewPost({
        title: '',
        content: '',
        categorie: '',
        updatedAt: new Date().toLocaleDateString(),
      });
    };
  
    return (
        <div className='bg-gray-100'>
            <div className="px-2 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">  
                {/* Show Table/Cards only if we are not editing or creating a post */}
                {!editingPost && !isCreating && (
                <>
                    <button
                    onClick={() => setIsCreating(true)}
                    className="bg-indigo-500 text-white px-4 py-2 rounded-lg mb-4 hover:bg-indigo-700"
                    >
                    Create New Post
                    </button>
        
                    {/* Table for Large Screens */}
                    <div className="hidden lg:block">
                    <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                        <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">ID</th>
                            <th className="py-3 px-6 text-left">Title</th>
                            <th className="py-3 px-6 text-left">categorie</th>
                            <th className="py-3 px-6 text-left">Created At</th>
                            <th className="py-3 px-6 text-right">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="text-gray-700 text-sm">
                        {posts.map((post) => (
                            <tr key={post.id} className="border-b border-gray-200">
                            <td className="py-3 px-6 text-left">{post.id}</td>
                            <td className="py-3 px-6 text-left">{post.title}</td>
                            <td className="py-3 px-6 text-left">{post.categorie}</td>
                            <td className="py-3 px-6 text-left">{post.updatedAt}</td>
                            <td className="py-3 px-6 text-right">
                                <button
                                className="bg-gray-200 text-black px-4 py-2 mb-2 rounded-lg mr-2 hover:bg-gray-700 w-full"
                                onClick={() => handleEdit(post)}
                                >
                                Edit
                                </button>
                                <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-900 w-full"
                                onClick={() => handleDelete(post.id)}
                                >
                                Delete
                                </button>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
        
                    {/* Cards for Mobile View */}
                    <div className="lg:hidden grid grid-cols-1 gap-4">
                    {posts.map((post) => (
                        <div
                        key={post.id}
                        className="bg-white p-4 rounded-lg shadow-lg border border-gray-200"
                        >
                        <h2 className="text-xl font-bold">{post.title}</h2>
                        <p className="text-sm text-gray-500">categorie: {post.categorie}</p>
                        <p className="text-sm text-gray-500">Created At: {post.updatedAt}</p>
                        <div className="mt-4 flex justify-end">
                            <button
                            className="bg-gray-200 text-black  px-4 py-2 rounded-lg mr-2 hover:bg-blue-700"
                            onClick={() => handleEdit(post)}
                            >
                            Edit
                            </button>
                            <button
                            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                            onClick={() => handleDelete(post.id)}
                            >
                            Delete
                            </button>
                        </div>
                        </div>
                    ))}
                    </div>
                </>
                )}
        
                {/* Show Form only if we are editing or creating a post */}
                {(editingPost || isCreating) && (
                <div className="mt-8">
                    <h2 className="text-xl font-bold mb-2">
                    {editingPost ? 'Edit Post' : 'Create New Post'}
                    </h2>
                    <input
                    type="text"
                    className="border border-gray-300 rounded-lg px-4 py-2 w-full mb-4"
                    placeholder="Post Title"
                    value={newPost.title}
                    onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}
                    />
                    <input
                    type="text"
                    className="border border-gray-300 rounded-lg px-4 py-2 w-full mb-4"
                    placeholder="categorie"
                    value={newPost.categorie}
                    onChange={(e) => setNewPost({ ...newPost, categorie: e.target.value })}
                    />
                    <textarea
                    className="border border-gray-300 rounded-lg px-4 py-2 w-full mb-4 min-h-[400px]"
                    placeholder="Post Content"
                    value={newPost.content}
                    onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
                    />
                    {editingPost ? (
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                        onClick={handleSaveEdit}
                    >
                        Save Changes
                    </button>
                    ) : (
                    <button
                        className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
                        onClick={handleCreate}
                    >
                        Create Post
                    </button>
                    )}
        
                    <button
                    onClick={() => {
                        setEditingPost(null);
                        setIsCreating(false);
                    }}
                    className="ml-4 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700"
                    >
                    Cancel
                    </button>
                </div>
                )}
            </div>
        </div>
    );
  };